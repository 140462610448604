import { track } from '@dfds-frontend/tracking'
import { Accordion } from '@dfds-ui/react-components'
import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { useState } from 'react'

import { isContentfulType } from '../../utils'
import { PageContentTextSection } from '../PageContentTextSection'
import { PaxTimeTable } from '../PaxTimeTable'

const AccordionList = ({
  showTitle,
  itemsCollection,
  title,
  defaultExpanded,
}) => {
  const [isOpenHead, setIsOpenHead] = useState(defaultExpanded ?? false)
  const onToggleHead = () => setIsOpenHead((s) => !s)
  const NestedItems = itemsCollection?.items.map((item) => {
    return <AccordionItem key={item.sys.id} {...item} />
  })
  return (
    <div
      css={css`
        margin-bottom: 40px;
      `}
    >
      {showTitle && title ? (
        <Accordion heading={title} isOpen={isOpenHead} onToggle={onToggleHead}>
          {NestedItems}
        </Accordion>
      ) : (
        <>{NestedItems}</>
      )}
    </div>
  )
}

const AccordionItem = (props) => {
  const [open, setOpen] = useState(false)
  const onToggle = () => {
    track({
      event: 'accordionClick',
      accordionTitle: props.title,
      accordionContentfulId: props.sys.id,
    })
    setOpen((open) => !open)
  }
  return (
    <Accordion
      key={props.title}
      heading={props.title}
      onToggle={() => onToggle()}
      isOpen={open}
      hideDivider={true}
    >
      {isContentfulType(
        props.content.__typename,
        'contentful_PageContentTextSection',
      ) && <PageContentTextSection noPadding noSpacing {...props.content} />}
      {isContentfulType(
        props.content.__typename,
        'contentful_PaxTimeTable',
      ) && <PaxTimeTable {...props.content} />}
    </Accordion>
  )
}

export const accordionListFragment = graphql`
  fragment PageContentAccordionList on contentful_PageContentAccordionList {
    __typename
    sys {
      id
    }
    title
    showTitle
    defaultExpanded
    itemsCollection(limit: 20) {
      items {
        ... on contentful_AccordionListItem {
          __typename
          sys {
            id
          }
          title
          content {
            __typename
            ...PaxTimeTable
            ...PageContentTextSection
          }
        }
      }
    }
  }
`

export default AccordionList
